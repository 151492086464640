.Contact {
  p {
    font-weight: 800;
  }

  a {
    font-weight: 400;
    text-decoration: none;
    color: black;
  }
}

.Contact__Form {
  form {
    display: flex;
    flex-direction: column;

    input:first-child {
      padding-top: 5px;
    }

    input,
    textarea {
      font-size: 0.9em;
      padding: 20px 0;
      border-style: none;
      border-radius: 0;
      border-bottom: 1px solid black;
      color: black;
    }

    input:last-child {
      display: flex;
      align-self: flex-end;
      padding: 10px 0;
      font-weight: 800;
      background-color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      border: none;
    }
  }

  h3 {
    margin-top: 20px;
    font-size: 12px;
    text-transform: uppercase;
  }
}
