.ArticleDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 40%;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
}

.ArticleDetail__Content {
  padding: 5px 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  p {
    margin: 5px;
    text-align: center;
  }

  @media (max-width: $breakpoint-md) {
    padding: 0 10px;
  }
}

.ArticleDetail__Title {
  font-size: 2.2rem;
  letter-spacing: 6.8px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (max-width: $breakpoint-md) {
    font-size: 1.8rem;
  }
}
