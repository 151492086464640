.Footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;

  span {
    margin-left: 5px;
  }

  a {
    font-size: 0.7rem;
    color: black;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 16px;
      height: 18.28px;
    }
  }
}
