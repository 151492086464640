.Navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    padding-inline-start: 0px;
  }

  @media (max-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
}

.Navigation__Nav {
  display: flex;
  list-style: none;
  flex-direction: row;
  font-weight: bold;

  a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    color: #000000;
    text-transform: uppercase;
  }

  li {
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }
  }

  a:hover {
    color: #000000;
    text-decoration: line-through;
  }

  .Navigation__Active {
    text-decoration: line-through;
  }
}
