.Article {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  img {
    width: 20%;
    margin: auto;
  }

  @media (max-width: $breakpoint-md) {
    img {
      width: 33%;
    }
  }

  @media (max-width: $breakpoint-sm) {
    img {
      width: 100% !important;
      height: 100%;
    }
  }
}
