// abstracts
@import './abstracts/colors';
@import './abstracts/variables';

// base
@import './base/typography';

// mixins
@import './mixins/fonts';

// atoms

// molecules
@import '../components/molecules/Article/Article';
@import '../components/molecules/Article/ArticleDetail';
@import '../components/molecules/Footer/Footer';
@import '../components/molecules/Layout';
@import '../components/molecules/Navigation/Navigation';
@import '../components/molecules/SinglePage/SinglePageContent';
@import '../components/molecules/SinglePage/SinglePageHeader';

@import '../pages/contact/Contact';

body {
  @include font-primary;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  max-width: 1200px;
  width: 80%;
  letter-spacing: 1.8px;
}

h2 {
  margin-bottom: 20px;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 6.8px;
}

p {
  @include font-secondary;
  letter-spacing: 0.2rem;
  line-height: 1.5;
  font-size: 0.8rem;
}
