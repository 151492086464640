.SinglePageHeader {
  margin: 0 auto;
  max-width: 700px;
  width: 60vw;
  letter-spacing: 1.8px;

  img {
    position: relative;
    width: 100%;
  }
}
